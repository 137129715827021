export function getToken() {
    return localStorage.getItem('token')
}

export function setToken(token) {
    localStorage.setItem('token', token)
}

export function removeToken() {
    localStorage.removeItem('token')
}

export function getUserInfo() {
    return localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {}
}

export function setUserInfo(data) {
    localStorage.setItem('userInfo', JSON.stringify(data))
}

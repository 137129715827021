<template>
    <div class="tags-view-container">
        <el-scrollbar
                ref="scrollContainer"
                :vertical="false"
                class="scroll-container tags-view-wrapper"
                @wheel.native.prevent="handleScroll"
        >
            <router-link v-for="(item, index) in visitedViews" :key="index" :to="item.path" class="tags-view-item"
                         :class="{'active' : item.path === $route.path}">
                {{item.meta.title}}
                <i class="el-icon-close" @click.prevent.stop="closeSelectedTag(item)" v-if="visitedViews.length > 1"/>
            </router-link>
        </el-scrollbar>
    </div>
</template>

<script>
    import {mapState, mapActions} from 'vuex'

    export default {
        name: 'tagsview',
        mounted() {},
        methods: {
            closeSelectedTag(item) {
                this.deleteView(item).then(views => {
                    this.toLastView(views)
                })
            },
            toLastView(views) {
                this.$router.push(views[views.length - 1]['path'])
            },
            ...mapActions('tagsView', ['deleteView'])
        },
        computed: {
            ...mapState('tagsView', ['visitedViews'])
        }
    }
</script>

<style lang="less" scoped>
    .tags-view-container {
        height: 34px;
        width: 100%;
        background: #fff;
        border-bottom: 1px solid #d8dce5;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .12), 0 0 3px 0 rgba(0, 0, 0, .04);

        .tags-view-wrapper {
            .tags-view-item {
                display: inline-block;
                position: relative;
                cursor: pointer;
                height: 26px;
                line-height: 26px;
                border: 1px solid #d8dce5;
                color: #495060;
                background: #fff;
                padding: 0 8px;
                font-size: 12px;
                margin-left: 5px;
                margin-top: 4px;

                &:first-of-type {
                    margin-left: 15px;
                }

                &:last-of-type {
                    margin-right: 15px;
                }

                &.active {
                    background-color: #409EFF;
                    color: #fff;
                    border-color: #409EFF;

                    &::before {
                        content: '';
                        background: #fff;
                        display: inline-block;
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        position: relative;
                        margin-right: 2px;
                    }
                }

                .el-icon-close:hover{
                    font-weight: bold;
                }
            }
        }
    }

    .scroll-container {
        white-space: nowrap;
        position: relative;
        overflow: hidden;
        width: 100%;

        ::v-deep {
            .el-scrollbar__bar {
                bottom: 0;
            }

            .el-scrollbar__wrap {
                height: 49px;
            }
        }
    }
</style>

<template>
    <div class="body-container">
        <div class="body-menu">
            <el-menu
                    :default-active="menu.length - 1"
                    class="el-menu-vertical-demo"
                    @open="handleOpen"
                    @close="handleClose"
                    background-color="#304156"
                    text-color="#fff"
                    active-text-color="#409EFF">
                <div class="logo-con"></div>
                <div class="logo-title">温度在线监测后台</div>
                <router-link v-for="(item, index) in menu" :key="index" :to="item.path">
                    <el-menu-item :index="index + 1">
                        <i :class="item.meta.icon"></i>
                        <span slot="title">{{item.meta.title}}</span>
                    </el-menu-item>
                </router-link>
            </el-menu>
        </div>
        <div class="body-box">
            <el-menu class="navbar" mode="horizontal">
                <breadcrumb class="breadcrumb-container"></breadcrumb>
            </el-menu>
            <tags-view></tags-view>
            <transition name="fade" mode="out-in">
                <keep-alive :include="visitedViews">
                    <router-view :key="key"/>
                </keep-alive>
            </transition>
        </div>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Breadcrumb'
    import TagsView from '@/components/TagsView'
    import {mapState} from 'vuex'

    export default {
        name: 'mainPage',
        components: {
            Breadcrumb,
            TagsView
        },
        data() {
            return {
                menu: []
            }
        },
        computed: {
            key() {
                return this.$route.name !== undefined ? this.$route.name + +new Date() : this.$route + +new Date()
            },
            ...mapState('tagsView', ['menus', 'visitedViews'])
        },
        mounted() {
            const userInfo = JSON.parse(localStorage.getItem('userInfo'))
            // 判断用户是否为超管
            if (userInfo['superAdmin'] != 1) {
                this.menu = this.menus.filter(item => item.name !== 'account' && item.name !== 'company')
            } else {
                this.menu = this.menus
            }
            this.menu = this.menu.filter(item => item.name !== 'user')
        },
        methods: {
            handleOpen(e) {
                console.log(e)
            },
            handleClose(e) {
                console.log(e)
            }
        }
    }
</script>

<style scoped>

</style>

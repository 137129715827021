<template>
    <div class="head-box">
        <el-breadcrumb class="app-breadcrumb" separator="/">
            <transition-group name="breadcrumb">
                <template v-for="(item,index)  in levelList">
                    <el-breadcrumb-item :key="index" v-if="item.meta.title">
                        <span v-if='item.redirect==="noredirect"||index==levelList.length-1' class="no-redirect">{{(item.meta.title)}}</span>
                        <router-link v-else :to="item.redirect||item.path">{{(item.meta.title)}}</router-link>
                    </el-breadcrumb-item>
                </template>
            </transition-group>
        </el-breadcrumb>
        <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link">
                您好，{{userInfo.realName}}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="a">修改密码</el-dropdown-item>
                <el-dropdown-item command="b">退出登录</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>

<script>
    import {getUserInfo, removeToken} from '@/utils'

    export default {
        data() {
            return {
                levelList: null,
                userInfo: {}
            }
        },
        created() {
            this.getBreadcrumb()
            console.log(getUserInfo())
            this.userInfo = getUserInfo()
        },
        watch: {
            $route() {
                this.getBreadcrumb()
            }
        },
        methods: {
            getBreadcrumb() {
                let matched = this.$route.matched.filter(item => item.name)
                // const first = matched[0]
                // if (first && first.name !== 'home') {
                //     matched = [{path: '/home', meta: {title: '首页'}}].concat(matched)
                // }
                this.levelList = matched
            },
            handleCommand(e) {
                if (e === 'a' && this.$route.path !== '/user') {
                    this.$router.push('/user')
                } else if (e ==='b') {
                    this.$confirm('确认退出登录?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.logOut()
                        this.$message({
                            type: 'success',
                            message: '退出成功!'
                        })
                    })
                }
            },
            logOut() {
                removeToken()
                this.$router.push('/login')
            }
        }
    }
</script>

<style rel="stylesheet/scss" lang="less" scoped>
    .app-breadcrumb.el-breadcrumb {
        display: inline-block;
        font-size: 14px;
        line-height: 50px;
        margin-left: 10px;

        .no-redirect {
            color: #97a8be;
            cursor: text;
        }
    }
</style>
